<template>
  <b-card no-body>
    <div class="m-2">
      <!-- Table Top -->
      <b-row>
        <!-- Search -->
        <b-col cols="7" md="6">
          <div class="d-flex align-items-center justify-content-end">
            <b-form-input
              class="d-inline-block"
              placeholder="Pesquise por título ou ID"
            />
          </div>
        </b-col>
      </b-row>
    </div>
    <b-table sticky-header responsive :items="items" :fields="fields" class="mb-0" striped hover>
      <template #cell(name)="data">
        <b-media vertical-align="center">
          <template #aside>
            <b-avatar
              size="32"
              :src="data.item.avatar"
              :text="avatarText(data.item.name)"
              :to="{ name: 'customer-view', params: { uuid: data.item.uuid } }"
            />
          </template>
          <b-link
            :to="{ name: 'customer-view', params: { uuid: data.item.uuid } }"
            class="font-weight-bold d-block text-nowrap"
          >
            {{ data.item.name }}
          </b-link>
          <small class="text-dark">{{ data.item.email }}</small>
        </b-media>
      </template>
      <template #cell(action)="data">
        <feather-icon
          :id="`ranking-${data.item.id}-view-icon`"
          icon="EyeIcon"
          class="cursor-pointer cursor"
          size="16"
        />
        <b-tooltip
          title="Editar produto"
          class="cursor-pointer"
          :target="`ranking-${data.item.id}-view-icon`"
        />
      </template>
    </b-table>
    <div class="mx-2 mb-2 mt-2 paginate-area">
      <b-row>
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-start"
        >
          <span class="text-muted">
            Exibindo 1 de 10 com total de 100 resultados
          </span>
        </b-col>
        <!-- Pagination -->
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-end"
        >
          <b-pagination
            v-model="currentPage"
            :total-rows="rows"
            first-number
            last-number
            prev-class="prev-item"
            next-class="next-item"
            class="mb-0"
          >
            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18" />
            </template>
            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18" />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </div>
  </b-card>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BTable,
  BPagination, BMedia, BAvatar,
} from 'bootstrap-vue';

import { avatarText } from '@core/utils/filter';

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BTable,
    BPagination,
    BMedia,
    BAvatar,
  },
  setup() {
    return { avatarText };
  },
  data() {
    return {
      currentPage: 1,
      perPage: 1,
      rows: 200,
      fields: [
        {
          key: 'position',
          label: 'Nº',
          class: 'text-center',
          thStyle: 'width: 80px',
        },
        {
          key: 'name',
          label: 'Nome',
        },
        {
          key: 'paid',
          label: 'Pago',
          class: 'text-center',
        },
        {
          key: 'action',
          label: '',
          class: 'text-center',
          thStyle: 'width: 100px',
        },
      ],
      items: [
        {
          uuid: '1',
          name: 'Jonas Cantes',
          email: 'cantes@gmail.com',
          position: 1,
          paid: 'R$ 5.000,00',
          image: '',
          action: '',
        },
        {
          uuid: '2',
          name: 'Roberto Silva',
          email: 'roberto@gmail.com',
          position: 2,
          paid: 'R$ 1.000,00',
          image: '',
          action: '',
        },
      ],
    };
  },
  methods: {
    confirmDelete() {
      this.$swal({
        title: 'Tem certeza?',
        text: 'Você não poderá reverter isso!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim, tenho!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.$swal({
            icon: 'success',
            title: 'Excluído com sucesso!',
            text: 'Operação realizada com sucesso!',
            customClass: {
              confirmButton: 'btn btn-success',
            },
          });
        }
      });
    },
  },
};
</script>
