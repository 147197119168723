var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card",
    { attrs: { "no-body": "" } },
    [
      _c(
        "div",
        { staticClass: "m-2" },
        [
          _c(
            "b-row",
            [
              _c("b-col", { attrs: { cols: "7", md: "6" } }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "d-flex align-items-center justify-content-end",
                  },
                  [
                    _c("b-form-input", {
                      staticClass: "d-inline-block",
                      attrs: { placeholder: "Pesquise por título ou ID" },
                    }),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c("b-table", {
        staticClass: "mb-0",
        attrs: {
          "sticky-header": "",
          responsive: "",
          items: _vm.items,
          fields: _vm.fields,
          striped: "",
          hover: "",
        },
        scopedSlots: _vm._u([
          {
            key: "cell(name)",
            fn: function (data) {
              return [
                _c(
                  "b-media",
                  {
                    attrs: { "vertical-align": "center" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "aside",
                          fn: function () {
                            return [
                              _c("b-avatar", {
                                attrs: {
                                  size: "32",
                                  src: data.item.avatar,
                                  text: _vm.avatarText(data.item.name),
                                  to: {
                                    name: "customer-view",
                                    params: { uuid: data.item.uuid },
                                  },
                                },
                              }),
                            ]
                          },
                          proxy: true,
                        },
                      ],
                      null,
                      true
                    ),
                  },
                  [
                    _c(
                      "b-link",
                      {
                        staticClass: "font-weight-bold d-block text-nowrap",
                        attrs: {
                          to: {
                            name: "customer-view",
                            params: { uuid: data.item.uuid },
                          },
                        },
                      },
                      [_vm._v(" " + _vm._s(data.item.name) + " ")]
                    ),
                    _c("small", { staticClass: "text-dark" }, [
                      _vm._v(_vm._s(data.item.email)),
                    ]),
                  ],
                  1
                ),
              ]
            },
          },
          {
            key: "cell(action)",
            fn: function (data) {
              return [
                _c("feather-icon", {
                  staticClass: "cursor-pointer cursor",
                  attrs: {
                    id: "ranking-" + data.item.id + "-view-icon",
                    icon: "EyeIcon",
                    size: "16",
                  },
                }),
                _c("b-tooltip", {
                  staticClass: "cursor-pointer",
                  attrs: {
                    title: "Editar produto",
                    target: "ranking-" + data.item.id + "-view-icon",
                  },
                }),
              ]
            },
          },
        ]),
      }),
      _c(
        "div",
        { staticClass: "mx-2 mb-2 mt-2 paginate-area" },
        [
          _c(
            "b-row",
            [
              _c(
                "b-col",
                {
                  staticClass:
                    "d-flex align-items-center justify-content-center justify-content-sm-start",
                  attrs: { cols: "12", sm: "6" },
                },
                [
                  _c("span", { staticClass: "text-muted" }, [
                    _vm._v(" Exibindo 1 de 10 com total de 100 resultados "),
                  ]),
                ]
              ),
              _c(
                "b-col",
                {
                  staticClass:
                    "d-flex align-items-center justify-content-center justify-content-sm-end",
                  attrs: { cols: "12", sm: "6" },
                },
                [
                  _c("b-pagination", {
                    staticClass: "mb-0",
                    attrs: {
                      "total-rows": _vm.rows,
                      "first-number": "",
                      "last-number": "",
                      "prev-class": "prev-item",
                      "next-class": "next-item",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "prev-text",
                        fn: function () {
                          return [
                            _c("feather-icon", {
                              attrs: { icon: "ChevronLeftIcon", size: "18" },
                            }),
                          ]
                        },
                        proxy: true,
                      },
                      {
                        key: "next-text",
                        fn: function () {
                          return [
                            _c("feather-icon", {
                              attrs: { icon: "ChevronRightIcon", size: "18" },
                            }),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                    model: {
                      value: _vm.currentPage,
                      callback: function ($$v) {
                        _vm.currentPage = $$v
                      },
                      expression: "currentPage",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }